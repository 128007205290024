import { AtlasService } from '@/modules/atlas/atlas-service';

// action types
export const DO_INIT_STATE = 'doInit';
export const SHOW = 'show';
export const HIDE = 'hide';
export const SAVE_SELECTED_MENU = 'saveSelectedMenu';
export const SHOW_INTERSECTION_MANAGER = 'showIntersectionManager';
export const HIDE_INTERSECTION_MANAGER = 'hideIntersectionManager';
export const HIDE_SELECTED_MENU = 'hideSelectedMenu';
export const SHOW_VISIBILITY_SETTINGS = 'showVisibilitySettings';
export const TOGGLE_ASIDE = 'updateToggleAside';

// mutation types
export const SET_SIDEBAR_STATE = 'setSidebarState';
export const INIT_STATE = 'init';
export const SET_SELECTED_MENU = 'setSelectedMenu';
export const SET_INTERSECTION_MANAGER_STATE = 'setIntersectionManagerState';
export const SET_INTEREST_AREA_STATE = 'setInterestAreaState';
export const SET_VISIBILITY_SETTINGS_STATE = 'setVisibilitySettingsState';
export const SET_TOGGLE_ASIDE_STATE = 'setToggleAsideState';

import { storeAsync } from '@/app-module';
const getDefaultState = ({ isSidebarVisible = false, isAtlasPublicView = false }) => ({
    visible: isSidebarVisible,
    selected: '',
    isIntersectionManagerVisible: false,
    isAtlasPublicView: isAtlasPublicView,
    isInterestAreaVisible: false,
    isVisibiltySettingsVisible: false,
    toggleState: false
});

const state = getDefaultState({});

const getters = {
    isVisible: (state) => state.visible,
    selected: (state) => state.selected,
    isInterestAreaVisible: (state) => state.isInterestAreaVisible,
    isIntersectionManagerVisible: (state) => state.isIntersectionManagerVisible,
    isVisibiltySettingsVisible: (state) => state.isVisibiltySettingsVisible,
    isAtlasPublicView: (state) => state.isAtlasPublicView,
    toggleState: (state) => state.toggleState,
};

const actions = {
    async [DO_INIT_STATE](context, isAtlasPublicView = false) {
        const isSidebarVisible = await AtlasService.getPersistedSidebarState();
        context.commit(INIT_STATE, { isSidebarVisible, isAtlasPublicView });
    },
    [SHOW](context) {
        context.commit(SET_SIDEBAR_STATE, true);
        AtlasService.persistSidebarState(true);
    },
    [HIDE](context) {
        context.commit(SET_SIDEBAR_STATE, false);
        AtlasService.persistSidebarState(false);
    },
    [SAVE_SELECTED_MENU](context, menu) {
        context.commit(SET_SELECTED_MENU, menu);
        if (menu === 'interest-areas') {
            context.commit(SET_INTEREST_AREA_STATE, true);
        }
    },
    [HIDE_SELECTED_MENU](context) {
       context.commit(SET_INTEREST_AREA_STATE, false);
    },
    [SHOW_INTERSECTION_MANAGER](context) {
        const sidebarStateVisible = context.state.visible;
        if (!sidebarStateVisible) context.commit(SET_SIDEBAR_STATE, true);
        context.commit(SET_INTERSECTION_MANAGER_STATE, true);
    },
    [HIDE_INTERSECTION_MANAGER](context) {
        context.commit(SET_INTERSECTION_MANAGER_STATE, false);
    },
    [SHOW_VISIBILITY_SETTINGS](context, isVisible) {
        context.commit(SET_VISIBILITY_SETTINGS_STATE, isVisible);
    },
    async [TOGGLE_ASIDE](context, isToggeled) {
        const toggleState = await AtlasService.getAsideToggleState();
        context.commit(SET_TOGGLE_ASIDE_STATE, toggleState);
    },
};

const mutations = {
    [INIT_STATE](state, { isSidebarVisible, isAtlasPublicView }) {
        const store = storeAsync();
        const atlasStore = store.state.atlas;
        store.replaceState({
            ...store.state,
            atlas: {
                ...atlasStore,
                sidebar: getDefaultState({ isSidebarVisible, isAtlasPublicView }),
                }
            }
        );
    },
    [SET_SIDEBAR_STATE](state, isVisible) {
        state.visible = isVisible;
    },
    [SET_SELECTED_MENU](state, menu) {
        state.selected = menu;
    },
    [SET_INTERSECTION_MANAGER_STATE](state, isVisible) {
        state.isIntersectionManagerVisible = isVisible;
    },
    [SET_INTEREST_AREA_STATE](state, isVisible) {
        state.isInterestAreaVisible = isVisible;
    },
    [SET_VISIBILITY_SETTINGS_STATE](state, isVisible) {
        state.isVisibiltySettingsVisible = isVisible;
    },
    [SET_TOGGLE_ASIDE_STATE](state, isVisible) {
        state.toggleState = isVisible;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
