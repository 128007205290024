<template>
  <st-modal
    ref="modal"
    hide-header-delimiter
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    hideable
    hide-header-close
    :title="$t('ATLAS.CREATE_LAYER_MODAL.HEADER')"
    :hideHeaderClose="isLoading"
    customClass="form-modal"
    :id="modalId"
  >
    <template #body>
      <form
        class="form"
        novalidate="novalidate"
        id="st_modal_create_layer_form"
      >
        <div class="row">
          <div class="col-12 col-md-6">
            <label>
              {{ fields.layer_type.label }}
              <span v-if="!isSimpleStaff">*</span>
            </label>
            <div class="form-group">
              <b-form-radio-group
                size="lg"
                v-model="model[fields.layer_type.name]"
                :options="layerTypeOptions"
                :name="fields.layer_type.name"
                @change="changeLayerType"
              ></b-form-radio-group>
            </div>
          </div>
        </div>
        <container-layer-form
          v-if="layerType === 'container'"
          :id="modalId"
          :layers="layers"
          ref="container_layer_form"/>
        <simple-layer-form
          v-else :id="modalId"
          ref="simple_layer_form"
          :isSimpleStaff="isSimpleStaff"
          :addLayerById="addLayerById"
          :canManage="canManage"
          />
      </form>
      <div class="pt-6">
        <div class="d-flex justify-content-between">
          <st-button
            size="large"
            variant="link"
            :callback="hide"
            :disabled="isLoading"
          >
            {{ $t("GENERAL.BUTTON.CANCEL") }}
          </st-button>
          <st-button
            size="large"
            variant="primary"
            :callback="doSubmit"
            :spinner="isLoading"
          >
            <span>{{ $t("GENERAL.BUTTON.ADD") }}</span>
          </st-button>
        </div>
      </div>
    </template>
  </st-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { generateRandomId } from "@/core/helpers/globalMethods";
import { AtlasLayerModel } from "@/modules/atlas/models/atlas-layer-model";
import { DEFAULT_LAYER_TYPE, PLOT_PRIVATE } from "@/modules/atlas/utils/constants";

import ContainerLayerForm from "@/modules/atlas/components/layer-manager/ContainerLayerForm";
import SimpleLayerForm from "@/modules/atlas/components/layer-manager/SimpleLayerForm";
import Message from '@/shared/message/message';

const { fields } = AtlasLayerModel;


export default {
  inheritAttrs: false,
  name: "LayerCreationFormModal",
  props: {
    file: null,
    addLayerById: {
        type: Boolean,
        default: false
    },
  },
  components: {
    ContainerLayerForm,
    SimpleLayerForm,
  },
  data() {
    return {
      modalId: `st-modal-layer-creation-${generateRandomId()}`,
      polling: null,
      pollingLayerById: null,
      fields,
      model: {},
      layerType: DEFAULT_LAYER_TYPE,
      errorMesage: '',
      layerTypeOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      loading: "shared/loading",
      layers: 'atlas/layer-manager/layers',
      isSimpleStaff: 'auth/isSimpleStaff',
      isSuperAdmin: 'auth/isSuperAdmin',
      simpleLayers: "atlas/layer-manager/simpleLayers",
      atlasPermissions: 'atlas/layer-manager/atlasPermissions',
    }),
    isLoading() {
      return this.loading["atlas-layer-manager/create"];
    },
    canManage() {
        return this.isSuperAdmin || this.atlasPermissions.hasPermissionToManage;
    }
  },
  watch: {
    addLayerById(val) {
        this.layerTypeOptions = (val || this.isSimpleStaff) ?
            this.simpleStaffLayerTypeOptions(this.fields.layer_type.options) : this.fields.layer_type.options;
    }
  },
  methods: {
    ...mapActions({
      doUpload: "atlas/layer-manager/upload",
      createLayer: "atlas/layer-manager/createLayer",
      updateLayer: "atlas/layer-manager/updateLayer",
      getLayerCreationStatus: "atlas/layer-manager/getLayerCreationStatus",
      addLayerToList: "atlas/layer-manager/addLayer",
    }),
    changeLayerType(value) {
      this.layerType = value;
    },
    async doSubmit() {
      let formRef = null;
      if (this.layerType === 'container') {
        formRef = this.$refs.container_layer_form;
      } else {
        formRef = this.$refs.simple_layer_form;
      }
       const layerModel = formRef.model;
       const fv = formRef.fv;
      if (this.model.layer_type === 'simple') {
        // New simple layer name must be unique
        const uniqueName = this.simpleLayers.some((el) => {
            return layerModel.name && el.name.toLowerCase() === layerModel.name.toLowerCase()
        });
        if (uniqueName) {
            fv.resetField('name');
            fv.addField('name', {
                validators: {
                    callback: {
                        message: this.$t('ATLAS.EDIT_MODAL.DUPLICATE_LAYER_NAME'),
                        callback: (input)=> {
                            return !this.simpleLayers.some((el) => el.name.toLowerCase() === input.value.toLowerCase());
                        },
                    },
                }
            });
            fv.revalidateField('name');
        }
      }

      const validate = await formRef.fv.validate();
      if (validate === "Valid") {
        this.doCreateLayer(formRef);
      }
    },
    async doCreateLayer(formRef) {
      const payload = formRef.model;
      payload.arcgis_imported_item_is_linked = !!formRef.model.arcgis_imported_item_is_linked;
      payload.layer_type = this.model.layer_type;

      // private vs. organization ownership selection based on role/permissions
      payload.private_layer_ownership_type = this.canManage ? formRef.model.private_layer_ownership_type : PLOT_PRIVATE;

      if (this.model.layer_type === 'container') {
        payload.layer_name = formRef.model.layer_name;
      }

      this.$emit("uploadStart");

      // Clear undefined values
      Object.keys(payload).forEach(key => payload[key] === undefined ? delete payload[key] : {});

      try {
        const response = await this.createLayer({payload, addLayerById: this.addLayerById});
        let id = '';
        if (this.addLayerById) {
            id = response.id;
            this.pollingLayerById = setInterval(() => {
                this.checkLayerCreationStatus(id, true);
            }, 3000);
        } else {
            const { data, uploadAccessToken } = response;
            id = data.id;
            const formData = new FormData();
            formData.append("files", this.file);
            const fileData = await this.doUpload({
              files: formData,
              uploadAccessToken,
            });
            const values = {
              source_file_id: fileData[0].id, // Only one file uploaded
            };
            await this.updateLayer({ id, values });
            this.polling = setInterval(() => {
                this.checkLayerCreationStatus(id, false);
            }, 3000);
        }

      } catch (error) {
        this.uploadStatus = "error";
        this.$emit("uploadEnd", { status: this.uploadStatus, addLayerById: this.addLayerById} );
      }
      this.hide();
    },
    async checkLayerCreationStatus(id, addLayerById) {
      try {
        const { status, ...layer } = await this.getLayerCreationStatus(id);
        this.uploadStatus = status;
        if (status === "completed") {
            if (addLayerById) Message.success('GENERAL.CREATED_SUCCESS');
            this.addLayerToList({ status, ...layer, is_published: false });
        }
      } catch (error) {
            if(addLayerById) {
                clearInterval(this.pollingLayerById);
            } else {
                clearInterval(this.polling);
            }
      } finally {
        if (this.uploadStatus !== "new" && this.uploadStatus !== "pending") {
            if(addLayerById) {
                this.$emit("uploadEnd", { status: this.uploadStatus, addLayerById});
                clearInterval(this.pollingLayerById);
            } else {
                this.$emit("uploadEnd", { status: this.uploadStatus, addLayerById});
                clearInterval(this.polling);
            }
        }
      }
    },
    show() {
      this.initValues();
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    initValues() {
      this.layerType = DEFAULT_LAYER_TYPE,
      this.model = {
        layer_type: this.layerType,
      };
    },
    simpleStaffLayerTypeOptions(layers) {
        return layers.filter(field => field.value === 'simple');
    }
  },
  created() {
    this.model.layer_type = this.layerType;
    this.layerTypeOptions = this.isSimpleStaff ?
        this.simpleStaffLayerTypeOptions(this.fields.layer_type.options) : this.fields.layer_type.options;
  },
  beforeDestroy() {
    if (this.polling) {
      clearInterval(this.polling);
    }
  },
};
</script>

