<template>
  <div :class="['layer-container', { 'fixed-height': isStaff , 'visibility-settings-modal-visible': isVisibiltySettingsVisible}]">
    <arcgis-apps id="apps" :apps="arcgisApps" :selectedArcgisApp="selectedArcgisApp" v-if="!isVisibiltySettingsVisible"/>
    <layer-group id="atlas" :layers="atlasLayers" :selectedLayer="selectedLayer"></layer-group>
    <layer-group
      v-if="isStaff"
      id="organisation"
      :layers="organisationLayers"
      :selectedLayer="selectedLayer"
      :isSimpleLayers="true"
      :isOrganisationSection="true"
      :isOrgSelected.sync="isOrgSelected"
    ></layer-group>
    <layer-group
      v-if="isStaff"
      id="private"
      :layers="privateLayers"
      :selectedLayer="selectedLayer"
      :isSimpleLayers="true"
      :isPrivateSection="true"
    ></layer-group>
  </div>
</template>
<script>
import { PLOT_PRIVATE } from "@/modules/atlas/utils/constants";
import LayerGroup from "@/modules/atlas/components/layer-manager/LayerGroup";
import ArcgisApps from "@/modules/atlas/components/arcgis-apps-manager/ArcgisApps";
import { mapGetters, mapActions } from "vuex";

const STATUS = {
  new: "info",
  pending: "warning",
  completed: "success",
  error: "danger",
};

export default {
  name: "LayerContainer",
  components: {
    LayerGroup,
    ArcgisApps,
  },
  props: {
    layers: {
      type: Array,
      default: () => [],
    },
    simpleLayers: {
      type: Array,
      default: () => [],
    },
    organisationLayers: {
      type: Array,
      default: () => [],
    },
    arcgisApps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
        return {
            isOrgSelected: null,
            orgLayers: [],
        }
  },
  computed: {
    ...mapGetters({
        isStaff: "auth/isStaff",
        layerCreationStatus: "atlas/layer-manager/layerCreationStatus",
        publishedLayerQueue: "atlas/layer-manager/publishedLayerQueue",
        selectedLayer: 'atlas/layer-manager/layer',
        selectedArcgisApp: 'atlas/arcgis-apps/selectedArcgisApp',
        isVisibiltySettingsVisible: 'atlas/sidebar/isVisibiltySettingsVisible',
    }),
    atlasLayers() {
      return this.layers.filter((el) => el.layer_type === "container") || [];
    },
    privateLayers() {
      return this.simpleLayers.filter((el) => el.layer_type === "simple" && el.private_layer_ownership_type == PLOT_PRIVATE) || [];
    },
  },
  mounted() {
    this.orgLayers = this.organisationLayers;
  },
  methods: {
    ...mapActions({
        doSelectOrg: 'atlas/layer-manager/organisationSelected',
    })
  },
  watch: {
    layerCreationStatus(newValue) {
        if(newValue) {
            const inPublishLayer = this.publishedLayerQueue.find((item) => item.layer.id === newValue.id);
            if (inPublishLayer) {
                const updatedLayer = this.simpleLayers[inPublishLayer.listIndex];
                const type = STATUS[newValue.status];
                this.$set(this.simpleLayers, inPublishLayer.listIndex, {...updatedLayer, publish_status:newValue.status, type});
            }
        }
    },
    isOrgSelected(val) {
        if(val) {
            const filteredLayers = this.orgLayers.filter(v => v.owner_org_id == val);
            this.doSelectOrg({orgId: val, layers: filteredLayers});
        } else {
            this.doSelectOrg({orgId: null, layers: this.orgLayers});
        }
    },
  },
};
</script>
<style lang="scss">
@import "./src/assets/sass/pages/atlas/atlas";
</style>
